import React from "react"
import Layout from "../layout/GeneralLayout"
import {
  Box,
  Paragraph,
  Heading,
  Button,
  Text,
} from "../components/commonElements"
import SEO from "../components/seo"
import styled from "styled-components"

const Container = styled(Box)`
  font-size: 1.8rem;
  max-width: ${props => `calc(${props.theme.containerSize} - 2rem)`};
  width: calc(100% - 2rem);
  border-radius: 1rem;
  min-height: 88vh;
  @media ${props => props.theme.device.tablet} {
    min-height: 80vh;
  }
  /* overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px !important;
  }
  &::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.white} !important;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.scrollbar} !important;
  }
`

const NotFoundPage = () => (
  <Layout>
    {/* <Box
      // maxWidth="120rem"

    > */}
    <SEO titleP="404: Not found" />
    <Container
      backgroundColor="white"
      padding="2rem"
      alignItems="center"
      alignSelf="center"
      // width="100%"
      mt="1rem"
      mb="1rem"
    >
      <Box width="60rem" alignItems="center">
        <Text textAlign="center" fontSize="8rem">
          404
        </Text>
        <Heading level={1} mb="2.2rem" textAlign="center">
          PAGE NOT FOUND
        </Heading>
        <Paragraph textAlign="center">
          The page you are looking for was moved, removed, renamed, temporarily
          unavailable or might be never existed.
        </Paragraph>
        <Button url="/" mt="1rem" alt="Go to homepage">
          GO TO HOMEPAGE
        </Button>
      </Box>
    </Container>
    {/* </Box> */}
  </Layout>
)

export default NotFoundPage

export const Head = () => <SEO titleP="404: Not found" pathname="/404/" />
